<template>
  <v-container class="pa-0 mx-0" fluid>
    <v-dialog v-model="dialog" max-width="500px" persistent>
      <template v-slot:activator="{ on: dialog, attrs }">
        <v-tooltip bottom>
          <template v-slot:activator="{ on: tooltip }">
            <v-btn
              :color="filterApplied ? 'orange' : 'msaBlue'"
              class="white--text"
              v-bind="attrs"
              v-on="{ ...tooltip, ...dialog }"
              v-blur
            >
              <v-icon> mdi-filter </v-icon>
            </v-btn>
          </template>
          <span>Filter</span>
        </v-tooltip>
      </template>
      <v-card>
        <v-card-title class="msaBlue white--text">
          <v-row>
            <v-col> Training Records Filter </v-col>
            <v-col class="shrink">
              <v-btn icon dark @click="dialog = false">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-card-title>
        <v-card-text class="mt-4">
          <v-row dense>
            <v-col cols="12"> User </v-col>
            <v-col cols="12">
              <CustomizedAutoComplete
                deletable-chips
                :value="filters.userIds"
                label="Select user(s)"
                hide-details
                :items="users"
                item-text="fullname"
                clearable
                multiple
                @change="filters.userIds = $event"
              />
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="12"> Has user attached </v-col>
            <v-col>
              <v-btn-toggle
                v-model="filters.hasUserAttached"
                mandatory
                dense
                color="msaBlue"
                style="width: 100%"
              >
                <v-btn style="width: 33%" class="text-capitalize" :value="1">
                  Yes
                </v-btn>
                <v-btn style="width: 33%" class="text-capitalize" :value="0">
                  No
                </v-btn>
                <v-btn style="width: 34%" class="text-capitalize" :value="2">
                  All
                </v-btn>
              </v-btn-toggle>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="12">Status </v-col>
            <v-col>
              <v-btn-toggle
                v-model="filters.employeeStatus"
                mandatory
                dense
                color="msaBlue"
                style="width: 100%"
              >
                <v-btn style="width: 33%" class="text-capitalize" :value="1">
                  Active
                </v-btn>
                <v-btn style="width: 33%" class="text-capitalize" :value="0">
                  Inactive
                </v-btn>
                <v-btn style="width: 34%" class="text-capitalize" :value="2">
                  All
                </v-btn>
              </v-btn-toggle>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="12">Group</v-col>
            <v-col>
              <CustomizedAutoComplete
                deletable-chips
                :value="filters.labels"
                label="Select group(s)"
                hide-details
                :items="labels"
                clearable
                multiple
                @change="filters.labels = $event"
              />
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="12">Supervisor </v-col>
            <v-col>
              <CustomizedAutoComplete
                deletable-chips
                :value="filters.supervisors"
                label="Select supervisor(s)"
                hide-details
                :items="supervisors"
                item-text="fullname"
                clearable
                multiple
                @change="filters.supervisors = $event"
              />
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="12">File Name </v-col>
            <v-col>
              <v-text-field
                v-model="filters.documentName"
                outlined
                hide-details
                placeholder="Enter file name"
                dense
                clearable
                @click:clear="onInputClear('documentName')"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="12"> Folder</v-col>
            <v-col cols="12">
              <CustomizedAutoComplete
                deletable-chips
                :value="filters.documentGroupIds"
                label="Select folder(s)"
                hide-details
                :items="groups"
                clearable
                multiple
                @change="filters.documentGroupIds = $event"
              />
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="12"> Assigned By </v-col>
            <v-col cols="12">
              <CustomizedAutoComplete
                deletable-chips
                :value="filters.assignedByUserIds"
                label="Select user(s)"
                hide-details
                :items="users"
                item-text="fullname"
                clearable
                multiple
                @change="filters.assignedByUserIds = $event"
              />
            </v-col>
          </v-row>
          <v-row dense align="center">
            <v-col cols="12"> Completed Date </v-col>
            <v-col cols="12" md="6">
              <v-menu
                v-model="completedFromMenu"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="filters.completedFrom"
                    label="From"
                    readonly
                    outlined
                    dense
                    clearable
                    hide-details
                    v-bind="attrs"
                    v-on="on"
                    @click:clear="onInputClear('completedFrom')"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="filters.completedFrom"
                  no-title
                  @input="completedFromMenu = false"
                ></v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="12" md="6">
              <v-menu
                v-model="completedToMenu"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="filters.completedTo"
                    label="To"
                    outlined
                    dense
                    clearable
                    hide-details
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    @click:clear="onInputClear('completedTo')"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="filters.completedTo"
                  no-title
                  @input="completedToMenu = false"
                ></v-date-picker>
              </v-menu>
            </v-col>
          </v-row>
          <v-row dense align="center">
            <v-col cols="12"> Recertification Date </v-col>
            <v-col cols="12" md="6">
              <v-menu
                v-model="expiredFromMenu"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="filters.recertificationFrom"
                    label="From"
                    readonly
                    outlined
                    dense
                    clearable
                    hide-details
                    v-bind="attrs"
                    v-on="on"
                    @click:clear="onInputClear('recertificationFrom')"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="filters.recertificationFrom"
                  no-title
                  @input="expiredFromMenu = false"
                ></v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="12" md="6">
              <v-menu
                v-model="expiredToMenu"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="filters.recertificationTo"
                    label="To"
                    outlined
                    dense
                    clearable
                    hide-details
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    @click:clear="onInputClear('recertificationTo')"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="filters.recertificationTo"
                  no-title
                  @input="expiredToMenu = false"
                ></v-date-picker>
              </v-menu>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="red" text @click="clear()"> clear </v-btn>
          <v-btn class="msaBlue white--text" @click="apply"> apply </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
export default {
  props: {
    current: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    filterApplied() {
      return (
        JSON.stringify(this.current) !=
        JSON.stringify(this.$constants.TRAINING_RECORDS_REPORT_FILTER)
      );
    },
  },
  data() {
    return {
      dialog: false,
      users: [],
      groups: [],
      filters: JSON.parse(
        JSON.stringify(this.$constants.TRAINING_RECORDS_REPORT_FILTER),
      ),
      completedFromMenu: false,
      completedToMenu: false,
      expiredFromMenu: false,
      expiredToMenu: false,
      supervisors: [],
      labels: [],
      dependenciesLoaded: false,
    };
  },
  watch: {
    dialog(nv) {
      if (nv) {
        if (!this.dependenciesLoaded) {
          this.loadDependencies();
        }
        this.filters = JSON.parse(JSON.stringify(this.current));
      }
    },
  },
  methods: {
    onInputClear(element) {
      this.filters[element] = '';
    },
    loadDependencies() {
      this.$axios
        .post('get-data-for-report-filter?format=json', {})
        .then((response) => {
          this.users = response.data.users;
          this.groups = response.data.groups;
          this.supervisors = response.data.supervisors;
          this.labels = response.data.labels;
          this.dependenciesLoaded = true;
        });
    },
    setFilters() {
      this.filters = JSON.parse(JSON.stringify(this.current));
    },
    clear() {
      this.filters = JSON.parse(
        JSON.stringify(this.$constants.TRAINING_RECORDS_REPORT_FILTER),
      );
      this.apply();
    },
    apply() {
      this.$emit('applyFilters', this.filters);
      this.dialog = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.v-select::v-deep .v-chip {
  /* chip background color msaBlue */
  background-color: #3564ac;

  /* chip text color */
  color: white;

  /* chip X color */
  button {
    color: white;
  }
}
</style>
